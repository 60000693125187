
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
interface Tipos {
  value: number | any;
  label: string;
}

@Component
export default class LenguageSelectorForm extends Vue {
  private lenguage = [] as any[];

  private selectedLenguage: Tipos = {
    value: "es",
    label: " "
  };

  private mounted() {
    this.lenguage.push({ value: "es", label: this.$t("lenguages.es") });
    this.lenguage.push({ value: "en", label: this.$t("lenguages.en") });
    this.selectedLenguage.value = this.lenguage[0].value;
    this.selectedLenguage.label = this.lenguage[0].label;
  }

  private updateData() {
    this.$axios
      .put("user/idioma", {
        lang: this.selectedLenguage.value
      })
      .then(() => {
        this.$store.commit("SET_LENGUAGE", this.selectedLenguage.value);
        this.$i18n.locale = this.selectedLenguage.value;
      })
      .finally(() => {
        this.lenguage = [];
        this.lenguage.push({ value: "es", label: this.$t("lenguages.es") });
        this.lenguage.push({ value: "en", label: this.$t("lenguages.en") });
        this.$swal({
          icon: "success",
          title: this.$t("notifications.success.title").toString(),
          text: this.$t("notifications.success.UPDATE_DATA").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
      });
  }
}
