import { render, staticRenderFns } from "./LenguageSelectorForm.vue?vue&type=template&id=c540432c&scoped=true"
import script from "./LenguageSelectorForm.vue?vue&type=script&lang=ts"
export * from "./LenguageSelectorForm.vue?vue&type=script&lang=ts"
import style0 from "./LenguageSelectorForm.vue?vue&type=style&index=0&id=c540432c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c540432c",
  null
  
)

export default component.exports