
import { Vue, Component, Prop } from "vue-property-decorator";
import NewApplication from "@/components/Dashboard/NewApplication.vue";

@Component({
  components: {
    NewApplication
  },
  meta: {
    // sets document title
    title: "Solicitud - Tarjeta Punta Arenas",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Completa la solicitud para tu Tarjeta Punta Arenas."
      },
      keywords: { name: "keywords", content: "Tarjeta Punta Arenas" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class NewApplicationView extends Vue {
  private mounted() {}
}
