
import { Component, Vue } from "vue-property-decorator";
import DataCard from "@/components/Common/DataCard.vue";

@Component({
  components: {
    DataCard
  }
})
export default class MisDatosComponent extends Vue {
  get user() {
    return this.$store.state.user;
  }

  get fullName() {
    return this.$store.getters.fullname;
  }

  private formatRut(rut: string) {
    rut = rut.replace(/\./g, "").replace(/-/g, "");
    const rutNumber = rut
      .substring(0, rut.length - 1)
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    const dv = rut.substring(rut.length - 1);
    return `${rutNumber}-${dv}`.toUpperCase();
  }
}
