
import { Component, Vue } from "vue-property-decorator";
import MisDatosComponent from "@/components/menu/MisDatosComponent.vue";

@Component({
  components: {
    MisDatosComponent
  }
})
export default class MisDatosView extends Vue {}
