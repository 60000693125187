
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";
import DataCard from "@/components/Common/DataCard.vue";
import PasswordChange from "@/components/auth/PasswordChange.vue";
import LenguageSelectorForm from "@/components/auth/LenguageSelectorForm.vue";

@Component({
  components: {
    DataCard,
    PasswordChange,
    LenguageSelectorForm
  }
})
export default class ConfiguracionComponent extends Mixins(FormValidator) {}
