
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";
import ApplicationForm from "@/components/Dashboard/ApplicationForm.vue";
import ApplicationFloatForm from "@/components/Dashboard/ApplicationFloatForm.vue";

interface Form {
  rut: string | null;
}

@Component({
  components: {
    ApplicationForm,
    ApplicationFloatForm,
    FormValidator
  },
  filters: {
    capitalize(value: string) {
      if (!value) {
        return "";
      }
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
})
export default class NewApplication extends Mixins(FormValidator) {
  protected formData: Form = {
    rut: ""
  };
  private formattedRut: string | null = "";
  private loading = false;
  private data: any = null;
  private vFloat = false;
  private rut: string | null = "";
  private step = 1;
  private selectTipoVecino = 0;
  private isFloat: any = null;
  private directoForm = false;

  private disableFlotante = false;
  private disableVecino = false;

  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.replace(/\./g, "");
    this.formData.rut = newValue;
    this.rut = this.formData.rut;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  @Watch("$store.state.cDisabled")
  private watchcDisabled(newValue: any) {
    if (newValue && !this.$store.state.cDisabledF) {
      this.disableFlotante = false;
      this.disableVecino = true;
    } else if (newValue && this.$store.state.cDisabledF) {
      this.disableFlotante = true;
      this.disableVecino = false;
    } else {
      this.disableFlotante = false;
      this.disableVecino = false;
    }
  }

  @Watch("$store.state.cDisabledF")
  private watchcDisabledF(newValue: any) {
    if (this.$store.state.cDisabled && !newValue) {
      this.disableFlotante = false;
      this.disableVecino = true;
    } else if (this.$store.state.cDisabled && newValue) {
      this.disableFlotante = true;
      this.disableVecino = false;
    } else {
      this.disableFlotante = false;
      this.disableVecino = false;
    }
  }

  private mounted() {
    if (this.$store.state.cDisabled && !this.$store.state.cDisabledF) {
      this.disableFlotante = false;
      this.disableVecino = true;
    } else if (this.$store.state.cDisabled && this.$store.state.cDisabledF) {
      this.disableFlotante = true;
      this.disableVecino = false;
    } else {
      this.disableFlotante = false;
      this.disableVecino = false;
    }
  }

  private validate() {
    if (!this.formData.rut || !this.validateRut(this.formData.rut)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.validate").toString(),
        text: this.$t("notifications.error.NO_RUT").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.loading = true;
    this.$axios
      .get("valid/rut?rut=" + this.formData.rut.toUpperCase())
      .then(res => {
        this.data = res.data;
        if (this.data.mensaje === "nuevo") {
          // if (this.data.titular === "si") {
          this.step = 3;
          this.selectTipoVecino = 1;
          this.isFloat = false;
          this.directoForm = true;
          // } else {
          //   this.step = 2;
          //   this.selectTipoVecino = 0;
          //   this.isFloat = false;
          //   this.directoForm = false;
          // }
        } else {
          if (this.data.tipoVecino === 1) {
            this.step = 3;
            this.selectTipoVecino = this.data.tipoVecino;
            this.isFloat = false;
            this.directoForm = true;
          } else {
            this.step = 3;
            this.selectTipoVecino = this.data.tipoVecino;
            this.isFloat = true;
            this.directoForm = true;
          }
        }
        this.$q.loading.hide();
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
        this.$q.loading.hide();
        const data = err.response.data;
        let status = "";
        if (data.data === "bloqueado") {
          status = this.$t("status.blocked").toString();
        } else if (data.data === "pagado") {
          status = this.$t("status.payed").toString();
        } else if (data.data === "entregada") {
          status = this.$t("status.physic_card").toString();
        } else if (data.data === "en revision") {
          status = this.$t("status.review").toString();
        } else if (data.data === "por pagar") {
          status = this.$t("status.unpaid").toString();
        } else if (data.data === "corrección") {
          status = this.$t("status.correction").toString();
        } else if (data.data === "anulado") {
          status = this.$t("status.annulled").toString();
        } else if (data.data === "vencido") {
          status = this.$t("status.expired").toString();
        } else if (data.data === "para entrega") {
          status = this.$t("status.delivering").toString();
        } else if (data.data === "para renovar") {
          status = this.$t("status.renew").toString();
        } else if (data.data === "vigente") {
          status = this.$t("status.valid").toString();
        } else if (data.data === "no vigente") {
          status = this.$t("status.invalid").toString();
        } else if (data.data === "tarjeta anulada") {
          status = this.$t("status.c_annuled").toString();
        } else if (data.data === "tarjeta bloqueada") {
          status = this.$t("status.c_blocked").toString();
        }
        if (data.data === "bloqueado") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.validate").toString(),
            text: this.$t(`notifications.error.RUT_BLOCKED`, {
              rut: this.formattedRut,
              status
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (data.data === "creado") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.validate").toString(),
            text: this.$t(`notifications.error.NEIGHBOR_EXIST`, {
              rut: this.formattedRut,
              status
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (data.data === "anulado") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.validate").toString(),
            text: this.$t(`notifications.error.CANCELED_REQUEST`, {
              rut: this.formattedRut,
              status
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.validate").toString(),
            text: this.$t(`notifications.error.${data.message}`, {
              rut: this.formattedRut,
              status
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        }
      });
  }
  private selectTipo(value) {
    this.loading = true;
    if (value === 1) {
      this.selectTipoVecino = value;
      this.loading = false;
      this.isFloat = false;
    } else {
      this.selectTipoVecino = value;
      this.loading = false;
      this.isFloat = true;
    }
    this.step = 3;
  }

  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
