
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";
import ApplicationForm from "@/components/Dashboard/ApplicationForm.vue";
import ApplicationFloatForm from "@/components/Dashboard/ApplicationFloatForm.vue";

interface SelectOption {
  value: number;
  label: string;
}
interface ApiData {
  tipo: number;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  rut: string;
  fechaNacimiento: Date;
  estadoCivil: number;
  nacionalidad: number;
  sexo: number;
  prevision: number;
  direccion: {
    calle: string;
    calle_id: number;
    numero: string;
    bloque?: string;
    depto?: string;
    referencia?: string;
  };
  email: string;
  emailFamilia: string;
  telefono: string;
  ocupacion?: string;
  profesion?: string;
  acreditacion: string;
  acreditacion_ext: string;
  carnet: string;
  carnet_ext: string;
  observacion: string;
  mensaje: string;
  titular: string;
  tipoVecino: number;
}

interface Tipos {
  value: number | any;
  label: string;
}

@Component({
  components: {
    ApplicationForm,
    ApplicationFloatForm
  }
})
export default class ApplicationsFloat extends Mixins(FormValidator) {
  @Prop({ required: true })
  private data!: ApiData;
  @Prop({ required: true })
  private rut!: string;

  private selectTipoVecino = "si";
  private loading = false;
  private vOptions: SelectOption[] = [];
  private flotante: Tipos = {
    value: null,
    label: ""
  };
  private isFloat: any = null;
  private dialogTV = false;
  private selectTipoVecinoTipo = 0;

  private mounted() {
    this.vOptions.push(
      { value: 1, label: this.$t("meta.neighbor_types.neighbor").toString() },
      { value: 2, label: this.$t("meta.neighbor_types.friend").toString() }
    );
  }

  private Send() {
    this.loading = true;
    this.selectTipoVecinoTipo = 0;
    if (this.flotante.value === 1) {
      this.selectTipoVecino = "no";
      this.loading = false;
      this.isFloat = false;
    } else {
      this.selectTipoVecino = "no";
      this.loading = false;
      this.isFloat = true;
    }
  }

  @Watch("flotante")
  private dataFlotante(newValue: any) {
    this.data.tipoVecino = newValue.value;
  }
}
